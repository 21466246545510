:root {
  --accent-color: #2ad5dc;
}



body.chakra-ui-dark  {
  background: url("./assets/images/bg.webp") !important;
  height: 100vh !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
body.chakra-ui-light {
  background: url("./assets/images/bg-light.webp") !important;
  height: 100vh !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}